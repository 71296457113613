@import '../../sass/variables/fonts';
@import '../../sass/variables/spaces';
@import '../../sass/variables/transitions';
@import '../../sass/variables/colors';

@import '../../sass/helpers/breakpoints';

.navBar {
	@include if-device(phone) {
		.ant-tabs-nav {
			padding: 0;
			.ant-tabs-tab {
				margin: 0;
			}
		}
	}
}
