.ant-layout {
	background-color: #f7f8fa;
}
.ant-layout-header {
	padding: 0 12px;
	background: #002915;
}
.ant-layout-footer {
	padding: 0 12px;
}
.ant-card-bordered {
	border-color: #e0e3ed;
	border-radius: 5px;
}

.ant-menu-item-group > .ant-menu-item-group-title {
	margin-top: 16px;
	margin-bottom: 16px;
	font-size: 13px;
}
.ant-menu-item-group > .ant-menu-item-group-title::after {
	position: relative;
	top: 12px;
	display: block;
	width: calc(100% - 20px);
	height: 1px;
	background: #f0f0f0;
	content: '';
}
@include if-device(phone) {
	.ant-card-body {
		padding: 12px;
	}
	.ant-page-header-heading-left {
		align-items: baseline;
		flex-direction: column;
	}
}

.ant-dropdown-navlink {
	color: rgba(255, 255, 255, 0.8);
	&:hover {
		color: rgba(255, 255, 255, 1);
	}
}
.ant-menu {
	&.ant-menu-dark {
		&.ant-menu-horizontal {
			> .ant-menu-item:hover {
				background-color: initial;
			}
		}
		background: #002915;
		.ant-menu-item {
			font-weight: 600;
			padding: 0 8px;
			> a {
				color: hsla(0, 0%, 100%, 0.5);
				padding: 4px 8px;
				border-radius: 4px;
			}
			.active :not([data-href='/']) {
				color: #fff;
			}
		}
		.ant-menu-item-selected {
			background-color: transparent;
			> a {
				color: #fff;
				background-color: #fafafa29;
			}
		}
		.ant-menu-submenu {
			padding: 0 8px;
			vertical-align: middle;
			.ant-menu-submenu-title {
				padding: 0 8px;
			}
		}
		.ant-menu-submenu-selected {
			.ant-menu-submenu-title {
				line-height: 20px;
				padding: 4px 8px;
				border-radius: 4px;
				color: #fff;
				background-color: #fafafa29;
			}
		}
	}
}

.ant-menu-horizontal {
	> .ant-menu-item {
		top: -1px;
	}
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
	right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
	margin-left: -1px;
	border-left: 1px solid #f0f0f0;
}
.ant-tabs-left
	> .ant-tabs-content-holder
	> .ant-tabs-content
	> .ant-tabs-tabpane,
.ant-tabs-left
	> div
	> .ant-tabs-content-holder
	> .ant-tabs-content
	> .ant-tabs-tabpane {
	padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
	left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
	order: 0;
	margin-right: -1px;
	border-right: 1px solid #f0f0f0;
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
	padding: 8px 0;
	font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
	padding: 16px 0;
	font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
	padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
	padding: 7px 16px 6px;
}
.ant-tabs-right
	> .ant-tabs-content-holder
	> .ant-tabs-content
	> .ant-tabs-tabpane,
.ant-tabs-right
	> div
	> .ant-tabs-content-holder
	> .ant-tabs-content
	> .ant-tabs-tabpane {
	padding-right: 24px;
}
.ant-tabs-dropdown {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	display: block;
}
.ant-tabs-dropdown-hidden {
	display: none;
}
.ant-tabs-dropdown-menu {
	max-height: 200px;
	margin: 0;
	padding: 4px 0;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: left;
	list-style-type: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	outline: none;
	-webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
	min-width: 120px;
	margin: 0;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item:hover {
	background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background: 0 0;
	cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
	margin: 0;
	padding: 8px 16px;
	background: #fafafa;
	border: 1px solid #f0f0f0;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
	color: #1890ff;
	background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
	visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-top
	> div
	> .ant-tabs-nav
	.ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom
	> div
	> .ant-tabs-nav
	.ant-tabs-tab:not(:last-of-type) {
	margin-right: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 2px 2px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 0 2px 2px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-left
	> div
	> .ant-tabs-nav
	.ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right
	> div
	> .ant-tabs-nav
	.ant-tabs-tab:not(:last-of-type) {
	margin-bottom: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 2px 0 0 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 2px 2px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
	border-left-color: #fff;
}
.ant-tabs {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	line-height: 46px;
	white-space: nowrap;
	border: 0;
	border-bottom: 1px solid #f0f0f0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
	position: relative;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	overflow: hidden;
	white-space: nowrap;
	-webkit-transform: translate(0);
	transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
	position: absolute;
	z-index: 1;
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	content: '';
	pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: stretch;
	align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
	position: absolute;
	visibility: hidden;
	pointer-events: none;
}

.ant-btn > .ant-btn-loading-icon .anticon {
	padding-right: 8px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
	width: 2px;
	left: auto;
}

// AntD Select
.ant-select {
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	cursor: pointer;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #40a9ff;
	border-right-width: 1px !important;
}
.ant-select-selection-item {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
	.ant-select-selection-item *::-ms-backdrop,
	.ant-select-selection-item {
		-ms-flex: auto;
		flex: auto;
	}
}
.ant-select-selection-placeholder {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	opacity: 0.4;
}
@media all and (-ms-high-contrast: none) {
	.ant-select-selection-placeholder *::-ms-backdrop,
	.ant-select-selection-placeholder {
		-ms-flex: auto;
		flex: auto;
	}
}
.ant-select-arrow {
	display: inline-block;
	font-style: normal;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	top: 53%;
	right: 11px;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 1;
	text-align: center;
	pointer-events: none;
}
.ant-select-arrow > * {
	line-height: 1;
}
.ant-select-arrow svg {
	display: inline-block;
}
.ant-select-arrow::before {
	display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
	display: block;
}
.ant-select-arrow .anticon {
	vertical-align: top;
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-arrow .anticon > svg {
	vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
	pointer-events: auto;
}
.ant-select-clear {
	position: absolute;
	top: 50%;
	right: 11px;
	z-index: 1;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	font-style: normal;
	line-height: 1;
	text-align: center;
	text-transform: none;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	-webkit-transition: color 0.3s ease, opacity 0.15s ease;
	transition: color 0.3s ease, opacity 0.15s ease;
	text-rendering: auto;
}
.ant-select-clear::before {
	display: block;
}
.ant-select-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
	opacity: 1;
}
.ant-select-dropdown {
	margin: 0;
	color: rgba(0, 0, 0, 0.65);
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 4px 0;
	overflow: hidden;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	-webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
	display: none;
}
.ant-select-dropdown-empty {
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-select-item-group {
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	cursor: default;
}
.ant-select-item-option {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.ant-select-item-option-content {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-select-item-option-state {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: rgba(0, 0, 0, 0.65);
	font-weight: 600;
	background-color: #e6f7ff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
	.ant-select-item-option-state {
	color: #1890ff;
}
.ant-select-item-option-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-item-option-grouped {
	padding-left: 24px;
}
.ant-select-lg {
	font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
	background-color: transparent !important;
	border-color: transparent !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #ffffff;
	border: 2px solid #dfe1e6;
	border-radius: 8px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	width: 100%;
	height: 32px;
	padding: 0 11px;
	line-height: 32px;
}
.ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
	cursor: text;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
	position: absolute;
	top: 0;
	right: 11px;
	bottom: 0;
	left: 11px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 25px;
}
.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-search-input {
	margin: 0;
	padding: 0;
	background: 0 0;
	border: none;
	outline: none;
	height: 30px;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
	height: auto;
}
.ant-form-item .ant-upload {
	background: 0 0;
}
.ant-form-item .ant-upload.ant-upload-drag {
	background: #fafafa;
}
.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
	width: 14px;
	height: 14px;
}
.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
	display: inline-block;
	margin-left: 8px;
	font-weight: 400;
	vertical-align: middle;
	cursor: pointer;
}
.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
	margin-left: 0;
}
.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
	display: block;
}
.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
	margin-left: 0;
}
.ant-form-item .ant-input-number + .ant-form-text {
	margin-left: 8px;
}
.ant-form-item .ant-input-number-handler-wrap {
	z-index: 2;
}
.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
	width: 100%;
}
.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
	width: auto;
}
.ant-form-inline {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	margin-right: 16px;
	margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
	margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
	display: inline-block;
	vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
	display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
	display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 0;
	flex: 1 1 0;
}
.ant-form-vertical .ant-form-item {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
	height: auto;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
	margin: 0;
	padding: 0 0 8px;
	line-height: 1.5715;
	white-space: initial;
	text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
	margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
	display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
	text-align: right;
}
@media (max-width: 575px) {
	.ant-form-item .ant-form-item-label {
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-form-item .ant-form-item-label > label {
		margin: 0;
	}
	.ant-form-item .ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-form-item .ant-form-item-label {
		text-align: right;
	}
	.ant-form .ant-form-item {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.ant-form .ant-form-item .ant-form-item-label,
	.ant-form .ant-form-item .ant-form-item-control {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.ant-col-xs-24.ant-form-item-label {
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-xs-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-xs-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-xs-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 767px) {
	.ant-col-sm-24.ant-form-item-label {
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-sm-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-sm-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-sm-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 991px) {
	.ant-col-md-24.ant-form-item-label {
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-md-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-md-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-md-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 1199px) {
	.ant-col-lg-24.ant-form-item-label {
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-lg-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-lg-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-lg-24.ant-form-item-label {
		text-align: right;
	}
}
@media (max-width: 1599px) {
	.ant-col-xl-24.ant-form-item-label {
		margin: 0;
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}
	.ant-col-xl-24.ant-form-item-label > label {
		margin: 0;
	}
	.ant-col-xl-24.ant-form-item-label > label::after {
		display: none;
	}
	.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
		text-align: right;
	}
}
.ant-form-item-has-feedback .ant-input {
	padding-right: 24px;
}
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
	padding-right: 18px;
}
.ant-form-item-has-feedback
	.ant-input-search:not(.ant-input-search-enter-button)
	.ant-input-suffix {
	right: 28px;
}
.ant-form-item-has-feedback .ant-switch {
	margin: 2px 0 4px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-selection__clear,
.ant-form-item-has-feedback
	:not(.ant-input-group-addon)
	> .ant-select
	.ant-select-arrow,
.ant-form-item-has-feedback
	:not(.ant-input-group-addon)
	> .ant-select
	.ant-select-selection__clear {
	right: 28px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback
	:not(.ant-input-group-addon)
	> .ant-select
	.ant-select-selection-selected-value {
	padding-right: 42px;
}
.ant-form-item-has-feedback .ant-cascader-picker-arrow {
	margin-right: 17px;
}
.ant-form-item-has-feedback .ant-cascader-picker-clear {
	right: 28px;
}
.ant-form-item-has-feedback .ant-picker {
	padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-large {
	padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-small {
	padding-right: 25.2px;
}
.ant-form-item-has-feedback.ant-form-item-has-success
	.ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning
	.ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error
	.ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating
	.ant-form-item-children-icon {
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;
	width: 32px;
	height: 20px;
	margin-top: -10px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	visibility: visible;
	-webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	pointer-events: none;
}
.ant-form-item-has-feedback.ant-form-item-has-success
	.ant-form-item-children-icon
	svg,
.ant-form-item-has-feedback.ant-form-item-has-warning
	.ant-form-item-children-icon
	svg,
.ant-form-item-has-feedback.ant-form-item-has-error
	.ant-form-item-children-icon
	svg,
.ant-form-item-has-feedback.ant-form-item-is-validating
	.ant-form-item-children-icon
	svg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}
.ant-form-item-has-success.ant-form-item-has-feedback
	.ant-form-item-children-icon {
	color: #52c41a;
	-webkit-animation-name: diffZoomIn1 !important;
	animation-name: diffZoomIn1 !important;
}
.ant-form-item-has-warning .ant-form-item-explain,
.ant-form-item-has-warning .ant-form-item-split {
	color: #faad14;
}
.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
	background-color: #fff;
	border-color: #faad14;
}
.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input:not([disabled]):hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:not([disabled]):hover {
	border-color: #faad14;
}
.ant-form-item-has-warning .ant-input-affix-wrapper input:focus {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.ant-form-item-has-warning
	.ant-calendar-picker-open
	.ant-calendar-picker-input {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-prefix {
	color: #faad14;
}
.ant-form-item-has-warning .ant-input-group-addon {
	color: #faad14;
	background-color: #fff;
	border-color: #faad14;
}
.ant-form-item-has-warning .has-feedback {
	color: #faad14;
}
.ant-form-item-has-warning.ant-form-item-has-feedback
	.ant-form-item-children-icon {
	color: #faad14;
	-webkit-animation-name: diffZoomIn3 !important;
	animation-name: diffZoomIn3 !important;
}
.ant-form-item-has-warning
	.ant-select:not(.ant-select-borderless)
	.ant-select-selector {
	border-color: #faad14 !important;
}
.ant-form-item-has-warning
	.ant-select:not(.ant-select-borderless).ant-select-open
	.ant-select-selector,
.ant-form-item-has-warning
	.ant-select:not(.ant-select-borderless).ant-select-focused
	.ant-select-selector {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
	border-color: #faad14;
}
.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
	border-color: #faad14;
}
.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
	border-color: #ffc53d;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
	color: #ff4d4f;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
	background-color: #fff;
	border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
	border-color: #ff7875;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
	box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-input:not([disabled]):hover,
.ant-form-item-has-error .ant-input-affix-wrapper:not([disabled]):hover {
	border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-input-affix-wrapper input:focus {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
	border-color: #ff7875;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
	box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-input-prefix {
	color: #ff4d4f;
}
.ant-form-item-has-error .ant-input-group-addon {
	color: #ff4d4f;
	background-color: #fff;
	border-color: #ff4d4f;
}
.ant-form-item-has-error .has-feedback {
	color: #ff4d4f;
}
.ant-form-item-has-error.ant-form-item-has-feedback
	.ant-form-item-children-icon {
	color: #ff4d4f;
	-webkit-animation-name: diffZoomIn2 !important;
	animation-name: diffZoomIn2 !important;
}
.ant-form-item-has-error
	.ant-select:not(.ant-select-borderless)
	.ant-select-selector {
	border-color: #ff4d4f !important;
}
.ant-form-item-has-error
	.ant-select:not(.ant-select-borderless).ant-select-open
	.ant-select-selector,
.ant-form-item-has-error
	.ant-select:not(.ant-select-borderless).ant-select-focused
	.ant-select-selector {
	border-color: #ff7875;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
	box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error
	.ant-input-group-addon
	.ant-select.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
	border: 0;
}
.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
	border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
	border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
	border-color: #ff7875;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
	box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
	border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error
	.ant-mention-wrapper
	.ant-mention-editor:not([disabled]):hover {
	border-color: #ff4d4f;
}
.ant-form-item-has-error
	.ant-mention-wrapper.ant-mention-active:not([disabled])
	.ant-mention-editor,
.ant-form-item-has-error
	.ant-mention-wrapper
	.ant-mention-editor:not([disabled]):focus {
	border-color: #ff7875;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
	box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
	border-color: #ff7875;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
	box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-item-has-error .ant-transfer-list {
	border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
	border-color: #d9d9d9;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
	border-color: #40a9ff;
	border-right-width: 1px !important;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
	border-color: #40a9ff;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-form-item-has-error-leave .ant-form-item-explain {
	color: #ff4d4f;
}
.ant-form-item-is-validating.ant-form-item-has-feedback
	.ant-form-item-children-icon {
	display: inline-block;
	color: #1890ff;
}
.ant-form {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
}
.ant-form legend {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	padding: 0;
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px;
	line-height: inherit;
	border: 0;
	border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
	font-size: 14px;
}
.ant-form input[type='search'] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
	line-height: normal;
}
.ant-form input[type='file'] {
	display: block;
}
.ant-form input[type='range'] {
	display: block;
	width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
	height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.ant-form output {
	display: block;
	padding-top: 15px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
}
.ant-form .ant-form-text {
	display: inline-block;
	padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
	height: 24px;
}
.ant-form-small .ant-form-item-control-input {
	min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
	height: 40px;
}
.ant-form-large .ant-form-item-control-input {
	min-height: 40px;
}
.ant-form-item {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
	margin-bottom: 24px;
	vertical-align: top;
}
.ant-form-item-with-help {
	margin-bottom: 0;
}
.ant-form-item-hidden {
	display: none;
}
.ant-form-item-label {
	display: inline-block;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	overflow: hidden;
	white-space: nowrap;
	text-align: right;
	vertical-align: middle;
}
.ant-form-item-label-left {
	text-align: left;
}
.ant-form-item-label > label {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 32px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
}
.ant-form-item-label > label > .anticon {
	font-size: 14px;
	vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required::before {
	display: inline-block;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: '*';
}
.ant-form-hide-required-mark
	.ant-form-item-label
	> label.ant-form-item-required::before {
	display: none;
}
.ant-form-item-label > label::after {
	content: ':';
	position: relative;
	top: -0.5px;
	margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
	content: ' ';
}
.ant-form-item-control {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not(
		[class*=' ant-col-']
	) {
	width: 100%;
}
.ant-form-item-control-input {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 32px;
}
.ant-form-item-control-input-content {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
	clear: both;
	min-height: 24px;
	padding-top: 0;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	-webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.show-help-enter,
.show-help-appear {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
	opacity: 0;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
	-webkit-animation-name: antShowHelpIn;
	animation-name: antShowHelpIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
	-webkit-animation-name: antShowHelpOut;
	animation-name: antShowHelpOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}
@-webkit-keyframes antShowHelpIn {
	0% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes antShowHelpIn {
	0% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@-webkit-keyframes antShowHelpOut {
	to {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
		opacity: 0;
	}
}
@keyframes antShowHelpOut {
	to {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
		opacity: 0;
	}
}
@-webkit-keyframes diffZoomIn1 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes diffZoomIn1 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@-webkit-keyframes diffZoomIn2 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes diffZoomIn2 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@-webkit-keyframes diffZoomIn3 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes diffZoomIn3 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.ant-table-thead > tr > th {
	padding: 8px 16px;
	text-transform: uppercase;
	font-size: 12px;
	background: #fafbfc;
	color: #7c8698;
	font-style: normal;
	font-weight: 500;
}

// .ant-message-notice-content {
// 	align-items: center;
// 	background-color: #37383c;
// 	border-radius: 6px;
// 	color: #fff;
// 	display: inline-flex;
// 	font-family: "Apercu Pro", "Helvetica Neue", "Helvetica", "Tahoma", "Geneva",
// 		"Arial", sans-serif;
// 	font-size: 14px;
// 	font-weight: 600;
// 	height: 44px;
// 	box-shadow: 0 4px 10px rgba(55, 56, 60, 0.16),
// 		0 0 2px rgba(55, 56, 60, 0.48);
// 	padding: 4px 12px;
// 	pointer-events: auto;
// 	position: relative;
// 	top: 52px;
// }

// .ant-message-notice-content .ant-message-custom-content {
// 	font-size: 14px;
// 	font-weight: 500;
// }

.ant-modal-mask {
	background-color: rgba(#37383c, 0.8);
}
.ant-page-header-content {
	padding-top: 0;
}
.ant-table-pagination.ant-pagination {
	margin: 16px;
}
.ant-tooltip-inner {
	background-color: #000;
	padding: 12px 16px;
	font-size: 12px;
	font-weight: 600;
	border: 2px solid #dfe1e6;
	border-radius: 8px;
}
.ant-table {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	letter-spacing: -0.006em;
	color: #45526c;
}

// custom specific overrides
#rejectMeetingChangeForm_reason,
#approveMeetingChangeForm_slotId {
	.ant-radio-button-wrapper:not(:first-child):before {
		background-color: unset;
	}
	.ant-radio-button-wrapper-checked {
		border: 2px solid #02a971;
		color: #02a971;
		background-color: #e6f6f1;
		line-height: 36px;
		font-weight: 500;
	}
}
.ant-alert-info {
	background: #ebf2ff;
	font-size: 15px;
}
.ant-alert-message {
	color: black;
}
.ant-btn-primary {
	color: #fff;
	border-color: #2d3748;
	border: 2px solid #1890ff;
	border-radius: 8px;
}
.ant-input {
	background: #ffffff;
	border: 2px solid #dfe1e6;
	border-radius: 8px;
}
.ant-input-number-handler-wrap {
	border-radius: 8px 8px 8px 8px;
}
.ant-input-number {
	background: #ffffff;
	border: 2px solid #dfe1e6;
	border-radius: 8px;
}
.ant-picker {
	background: #ffffff;
	border: 2px solid #dfe1e6;
	border-radius: 8px;
}
.ant-pagination-item {
	border: 2px solid #dfe1e6;
	border-radius: 8px;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
	border: 2px solid #dfe1e6;
	border-radius: 8px;
}
.ant-btn {
	border-radius: 8px;
}

@media only screen and (max-width: 577px) {
	.ant-table-pagination {
		display: block;
	}
}

@include if-device(phone) {
	.ant-row,
	.ant-row:after,
	.ant-row:before {
		flex-direction: column;
	}
	.ant-page-header-footer {
		margin-top: 0px;
	}
	.ant-row-bottom {
		-ms-flex-align: end;
		align-items: flex-start;
	}
	.ant-col-5 {
		display: block;
		max-width: 100%;
	}
	.ant-col {
		width: 100%;
	}
	.ant-descriptions-bordered .ant-descriptions-item-content,
	.ant-descriptions-bordered .ant-descriptions-item-label {
		padding: 10px 5px;
	}
	.ant-col-17 {
		max-width: 100%;
	}
	.ant-descriptions-item-label {
		font-size: 12px;
	}
	.ant-descriptions-item-content {
		font-size: 12px;
	}
}

@media only screen and (max-width: 381px) {
	.ant-radio-button-wrapper {
		padding: 0 5px;
	}
	.ant-card-body {
		padding: 2px;
	}
}

.red {
	color: #ff4d4f;
}

.green {
	color: #02a971;
}
