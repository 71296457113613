$color-black: #000;
$color-upgradRed: #e95454;
$color-blackRock: #091e42;
$color-white: #ffffff;
// Neutral Colors
$color-g6: #45526c;
$color-g5: #7c8698;
$color-g4: #b4bac4;
$color-g3: #dfe1e6;
$color-g2: #f4f5f7;
$color-g1: #fafbfc;

$color-white_transclusent: #ffffff99;

$color-blue: #4f8afb;
$color-light-blue: #c4d8fe;
$color-lightest-blue: #ebf2ff;
$color-green: #02a971;
$color-dull-green: rgba(34, 175, 115, 0.4);
$color-light-green: #dafff2;
$color-lightest-green: #eef6f3;
$color-red: #e32525;
$color-tomato: #e95454;
$color-light-red: #fdecec;
$color-orange: #ffc107;
$color-disabled: #dfe1e6;

$green-gradient: linear-gradient(90deg, #25a46e -0.32%, #0ad1ad 102.27%);
$red-gradient: linear-gradient(to left, #ff5757, #d02035);
$blue-gradient: linear-gradient(90deg, #2357ba -0.32%, #4992ff 60.41%);
$orange-gradient: linear-gradient(90deg, #f58823 0%, #ffcb48 110.27%);
