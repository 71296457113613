body {
	height: 100%;
	background-color: $color-g1;
	overflow-x: hidden;
	font-size: 14px;
	line-height: 1.3;
	font-weight: 400;
	letter-spacing: normal;
	font-family: $font-family, -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: geometricPrecision;
	font-variant-ligatures: common-ligatures;
	color: $color-blackRock;
}

body * {
	transition: 0.2s $easing-productive;
	margin: 0;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
	font-weight: 400;
}

h1 {
	font-size: 32px;
}

h2 {
	font-size: 22px;
}

h3 {
	font-size: 20px;
}

h4 {
	font-size: 18px;
}

li {
	list-style-type: none;
}

small {
	font-size: 12px;
}

img {
	/* Makes sure image is never bigger than its parent */
	max-width: 100%;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $color-g4;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: $color-g4;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: $color-g4;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: $color-g4;
}
