/* stylelint-disable */
@mixin auto-generate-paddings(
	$values,
	$values-vertical,
	$values-horizontal,
	$prefix: ''
) {
	// Padding all
	@each $val in $values {
		.#{$prefix}p-#{$val} {
			padding: #{$val}px#{$scu-val-important-paddings};
		}
	}

	// Padding top, bottom and vertical
	@each $val in $values-vertical {
		.#{$prefix}pt-#{$val},
		.#{$prefix}pv-#{$val} {
			padding-top: #{$val}px#{$scu-val-important-paddings};
		}

		.#{$prefix}pb-#{$val},
		.#{$prefix}pv-#{$val} {
			padding-bottom: #{$val}px#{$scu-val-important-paddings};
		}
	}

	// Padding left, right and horizontal
	@each $val in $values-horizontal {
		.#{$prefix}pl-#{$val},
		.#{$prefix}ph-#{$val} {
			padding-left: #{$val}px#{$scu-val-important-paddings};
		}

		.#{$prefix}pr-#{$val},
		.#{$prefix}ph-#{$val} {
			padding-right: #{$val}px#{$scu-val-important-paddings};
		}
	}
}

// Auto generated padding classes

@include auto-generate-paddings(
	$scu-paddings,
	$scu-paddings-vertical,
	$scu-paddings-horizontal,
	$scu-val-prefix
);

@if $scu-build-tablet-classes {
	@include if-device(tablet) {
		@include auto-generate-paddings(
			$scu-tablet-paddings,
			$scu-tablet-paddings-vertical,
			$scu-tablet-paddings-horizontal,
			$scu-val-prefix-tablet
		);
	}
}

@if $scu-build-phone-tablet-classes {
	@include if-device(phone-tablet) {
		@include auto-generate-paddings(
			$scu-phone-tablet-paddings,
			$scu-phone-tablet-paddings-vertical,
			$scu-phone-tablet-paddings-horizontal,
			$scu-val-prefix-phone-tablet
		);
	}
}

@if $scu-build-phone-classes {
	@include if-device(phone) {
		@include auto-generate-paddings(
			$scu-phone-paddings,
			$scu-phone-paddings-vertical,
			$scu-phone-paddings-horizontal,
			$scu-val-prefix-phone
		);
	}
}
