.global {
	&Container {
		&__card {
			border: 1px solid $color-g3;
			border-top: none;
			background: $color-white;
			@include if-device(phone) {
				padding: 0;
			}
		}
	}
}
