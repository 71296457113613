@import '../../sass/variables/fonts';
@import '../../sass/variables/spaces';
@import '../../sass/variables/transitions';
@import '../../sass/variables/colors';

@import '../../sass/helpers/breakpoints';

.spinner_backdrop {
	opacity: 0.5;
	background-color: $color-white_transclusent;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 98;
	right: 0;
}

.spinner_container {
	position: fixed;
	left: 50%;
	top: 50%;
	z-index: 99;
	transform: translate(-50%, -50%);
}

.spinner {
	width: 50px;
	height: 50px;
	animation: rotate 2s linear infinite;

	& .spinner_path {
		stroke: tomato;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(120deg) scale(1.5);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}

	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}
