@import '../sass/variables/fonts';
@import '../sass/variables/spaces';
@import '../sass/variables/transitions';
@import '../sass/variables/colors';

@import '../sass/helpers/breakpoints';

.root {
	min-height: 100vh !important;
	background-color: #f7f8fa;
	.siteLayout {
		position: relative;
		margin: 142px auto 32px;
		width: 100%;
		@include if-device('desktop-tablet') {
			padding: 0 12px;
		}
	}
	&.isMobile {
		.siteLayout {
			margin: 114px auto 32px;
			max-width: 100%;
			width: 100%;
			position: relative;
			padding: 12px;
		}
	}
}
.mobileScreenBlocker {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 9999;
	background-color: #fff;
	color: #595959;
	font-size: 20px;
	padding: 48px;
	font-weight: bold;
}

// responsive

@include if-device(phone) {
	.root {
		&.isMobile {
			.siteLayout {
				padding: 0px;
				margin-top: 65px;
			}
		}
	}
}
