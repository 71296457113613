.customScrollBar {
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: lighten(#262626, 10%);
		visibility: hidden;
		border-radius: 7px;
	}

	&:hover {
		&::-webkit-scrollbar-thumb {
			visibility: visible;
		}
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: lighten(#262626, 15%);
	}

	&_nav {
		&::-webkit-scrollbar-thumb {
			background-color: rgba(black, 0.1);
		}
		&::-webkit-scrollbar-thumb:hover {
			background-color: rgba(black, 0.2);
		}
	}
}
