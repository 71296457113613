@font-face {
	font-family: 'Promixa Nova';
	font-style: normal;
	font-weight: 300;
	src: url('../../assets/fonts/proxima-nova/proxima-nova-light.eot'); /* IE9 Compat Modes */
	src: local('Proxima Nova Light'), local('Proxima-Nova-Light'),
		url('../../assets/fonts/proxima-nova/proxima-nova-light.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../assets/fonts/proxima-nova/proxima-nova-light.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-light.woff') format('woff'),
		/* Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-light.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../assets/fonts/proxima-nova/proxima-nova-light.svg#wf')
			format('svg'); /* Legacy iOS */
	font-display: swap;
}
@font-face {
	font-family: 'Promixa Nova';
	font-style: normal;
	font-weight: 400;
	src: url('../../assets/fonts/proxima-nova/proxima-nova-regular.eot'); /* IE9 Compat Modes */
	src: local('Proxima Nova Regular'), local('Proxima-Nova-Regular'),
		url('../../assets/fonts/proxima-nova/proxima-nova-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('../../assets/fonts/proxima-nova/proxima-nova-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-regular.woff')
			format('woff'),
		/* Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../assets/fonts/proxima-nova/proxima-nova-regular.svg#wf')
			format('svg'); /* Legacy iOS */
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: 'Promixa Nova';
	font-style: normal;
	font-weight: 600;
	src: url('../../assets/fonts/proxima-nova/proxima-nova-semi-bold.eot'); /* IE9 Compat Modes */
	src: local('Proxima Nova Semi Bold'), local('Proxima-Nova-Semi-Bold'),
		url('../../assets/fonts/proxima-nova/proxima-nova-semi-bold.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('../../assets/fonts/proxima-nova/proxima-nova-semi-bold.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-semi-bold.woff')
			format('woff'),
		/* Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-semi-bold.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../assets/fonts/proxima-nova/proxima-nova-semi-bold.svg#wf')
			format('svg'); /* Legacy iOS */
	font-display: swap;
}
@font-face {
	font-family: 'Promixa Nova';
	font-style: normal;
	font-weight: 700;
	src: url('../../assets/fonts/proxima-nova/proxima-nova-bold.eot'); /* IE9 Compat Modes */
	src: local('Proxima Nova Bold'), local('Proxima-Nova-Bold'),
		url('../../assets/fonts/proxima-nova/proxima-nova-bold.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../assets/fonts/proxima-nova/proxima-nova-bold.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-bold.woff') format('woff'),
		/* Modern Browsers */
			url('../../assets/fonts/proxima-nova/proxima-nova-bold.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../assets/fonts/proxima-nova/proxima-nova-bold.svg#wf') format('svg'); /* Legacy iOS */
	font-display: swap;
}
@font-face {
	font-family: 'Circular Std';
	font-style: normal;
	font-weight: 700;
	src: local('Circular Std'), local('Circular-Std'),
		/* Modern Browsers */
			url('../../assets/fonts/Circular-Std/CircularStd-Bold.ttf')
			format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Circular Std';
	font-style: normal;
	font-weight: 700;
	src: local('Circular Std'), local('Circular-Std'),
		/* Modern Browsers */
			url('../../assets/fonts/Circular-Std/CircularStd-Black.ttf')
			format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Circular Std';
	font-style: normal;
	font-weight: 700;
	src: local('Circular Std'), local('Circular-Std'),
		/* Modern Browsers */
			url('../../assets/fonts/Circular-Std/CircularStd-Book.ttf')
			format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Circular Std';
	font-style: normal;
	font-weight: 700;
	src: local('Circular Std'), local('Circular-Std'),
		/* Modern Browsers */
			url('../../assets/fonts/Circular-Std/CircularStd-Light.ttf')
			format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Circular Std';
	font-style: normal;
	font-weight: 700;
	src: local('Circular Std'), local('Circular-Std'),
		/* Modern Browsers */
			url('../../assets/fonts/Circular-Std/CircularStd-Medium.ttf')
			format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Circular Std';
	font-style: normal;
	font-weight: 700;
	src: local('Circular Std'), local('Circular-Std'),
		/* Modern Browsers */
			url('../../assets/fonts/Circular-Std/CircularStd-Regular.ttf')
			format('truetype');
	font-display: swap;
}
