@import '../../sass/variables/fonts';
@import '../../sass/variables/spaces';
@import '../../sass/variables/transitions';
@import '../../sass/variables/colors';

@import '../../sass/helpers/breakpoints';

.root {
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	border-top: 1px solid;
	border-color: #e3e7ef;
	width: 100%;
	height: 46px;
	line-height: 46px;
	padding: 0px 12px;

	@include if-device(phone) {
		flex-direction: column;
	}
	.firstList {
		display: flex;
		order: 1;
		width: auto;
		padding-inline-start: 0;
		@include if-device(phone) {
			display: none;
		}
	}
	.secondList {
		display: flex;
		order: 1;
		width: auto;
		@include if-device(phone) {
			flex-direction: column;
		}
	}
}
.listLink {
	display: inline-block;
	margin: 0;
	margin-left: 8px;
	color: #62738d;
	font-size: 12px;
	a {
		color: rgba(0, 0, 0, 0.65);
	}
}
