.icon {
	color: inherit;
	background-size: contain;
	min-width: 12px;
	min-height: 12px;
	background-position: center;
	background-repeat: no-repeat;
	font-style: normal;
	display: inline-block;
}
